import React from 'react';
import { Link } from 'react-router-dom';
import pic1 from  "../../Pic/about/pic1.png";
import pic2 from  "../../Pic/about/pic2.png";
import pic3 from  "../../Pic/about/pic3.png";
import pic4 from  "../../Pic/about/pic4.png";
import pic5 from  "../../Pic/about/pic5.png";
import pic6 from  "../../Pic/about/pic6.png";
import pic7 from  "../../Pic/about/pic7.png";
import pic8 from  "../../Pic/about/pic8.png";
import pic9 from  "../../Pic/about/pic9.png";
import { useTranslation } from 'react-i18next';


export default function About() {
  const { t , i18n } = useTranslation();
  return <>
          <div className='pt-5 mt-5  container-fluid about-section'>
            <div className='container'>
                <h1 className='text-center fw-bold mt-5'>{t("about h1 1")}</h1>
                <p>{t("about p")}</p> 
            </div>
            <div className='container about-main'>
                <h1 className='text-center  fw-bold'>{t("about h1 2")}</h1>
                <div>
                  <div className="row g-1">
                    <div className="col-md-6">
                       <div className='about-item'> 
                           <img className='w-100 p-3' src={i18n.language == 'en' ? pic2 : pic6} alt="" />
                       </div>
                    </div>
                    <div className="col-md-6">
                       <div className='about-item'> 
                           <img className='w-100 p-3' src={i18n.language == 'en' ? pic3 : pic7} alt="" />
                       </div>
                    </div>
                    <div className="col-md-6">
                       <div className='about-item'> 
                           <img className='w-100 p-3' src={i18n.language == 'en' ? pic4 : pic8} alt="" />
                       </div>
                    </div>
                    <div className="col-md-6">
                       <div className='about-item'> 
                           <img className='w-100 p-3' src={i18n.language == 'en' ? pic5 : pic9} alt="" />
                       </div>
                    </div>
                  </div>
                </div>
            </div>
            <div className='container'>
              <div className='d-flex justify-content-center'>
                  <img className='w-50' src={pic1} alt="" />
              </div>
              <h1 className='text-center mb-5'>{t("about h1 3")} </h1>
             </div>
             <div className='text-center mb-5'>
             <button  className='btn btn-color'> <Link className='serviceslink' to="/contact">{t("about h1 button")}</Link>  </button>
             </div>
    </div>
  </>
}
