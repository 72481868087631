import webhoisting from "../../Pic/Services Components/hosting.png";
import webdesign from "../../Pic/Services Components/web-design.png";
import webhdevelopment from "../../Pic/Services Components/web-development.png";
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function WebDevelopment() {
  const { t, i18n } = useTranslation();
  return <>
<div className='pt-5 mt-5  container'>
            <div className='row my-5 d-flex justify-content-center align-items-center'>
                <div className="col-md-7 col-10 ">
                    <div className='servicesItem'>
                            <h1 className={t("services_class")}>{t("webhoisting")}</h1>
                            <p className="fw-bold">{t("webhoisting_p")}</p>
                    </div>
                </div>
                <div className="col-md-5 col-10 ">
                    <div className='servicesItem'>
                            <img className='w-100 text-center' src={webhoisting}  alt="" />
                    </div>
                </div>
            </div>
            <div className='row my-5 d-flex justify-content-center align-items-center'>
                <div className="col-md-7 col-10 ">
                    <div className='servicesItem'>
                            <h1 className={t("services_class")}>{t("webdesign")}</h1>
                            <p className="fw-bold">{t("webdesign_p")}</p>
                    </div>
                </div>
                <div className="col-md-5 col-10 ">
                    <div className='servicesItem'>
                            <img className='w-100 text-center' src={webdesign}  alt="" />
                    </div>
                </div>
            </div>
            <div className='row my-5 d-flex justify-content-center align-items-center'>
                <div className="col-md-7 col-10 ">
                    <div className='servicesItem'>
                            <h1 className={t("services_class")}>{t("webdevelopment")}</h1>
                            <p className="fw-bold">{t("webdevelopment_p")}</p>
                    </div>
                </div>
                <div className="col-md-5 col-10 ">
                    <div className='servicesItem'>
                            <img className='w-100 text-center' src={webhdevelopment}  alt="" />
                    </div>
                </div>
            </div>
    </div>

  </>
}
