import React from 'react';
import { createHashRouter, RouterProvider} from "react-router-dom"
import Layout from './Component/Layout/Layout';
import Home from './Component/Home/Home';
import About from './Component/About/About';
import Contact from './Component/Contact/Contact';
import Services from './Component/Services/Services';
import DigitalMarketing from './Component/DigitalMarketing/DigitalMarketing';
import WebDevelopment from './Component/WebDevelopment/WebDevelopment';
import Gallery from './Gallery/Gallery.jsx';


import "../../app3/src/App.css"
import { useTranslation } from 'react-i18next';
import GraphicDesigns from './Component/Graphic Designs/GraphicDesigns';


let routes = createHashRouter([
  {path:"/" ,  element:<Layout/> , children:[
    {index:true, element:<Home/>},
    {path:"about", element:<About/>},
    {path:"contact", element:<Contact/>},
    {path:"services", element:<Services/> },
    {path:"digitalmarketing" , element:<DigitalMarketing/>},
    {path:"webdevlopment" , element:<WebDevelopment/>},
    {path:"graphicdesigns" , element:<GraphicDesigns/>},
    {path:"gallery" , element:<Gallery/>},
  ]}
])

export default function App() {
  const {i18n} = useTranslation()
  return <div dir={i18n.language == 'ar' ? 'rtl' : 'ltr'}>
    <RouterProvider router={routes} />
  </div>
}