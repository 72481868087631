import React from 'react';
import pic1 from "../../Pic/pic1.jpg";
import pic2 from "../../Pic/pic2.jpg";
import pic3 from "../../Pic/pic3.jpg";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Autoplay, Pagination, Navigation } from "swiper";

import { useTranslation } from 'react-i18next';

export default function Home() {
  const { t, i18n } = useTranslation();
return <>

    <Swiper
            spaceBetween={0}
            centeredSlides={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay ,  Pagination, Navigation]}
            className="mySwiper"
            dir='ltr'
          >
            <SwiperSlide><img src={pic2} alt="" />
               <div className='conne'>
                   <h1>{t("homehead")}</h1>
                   <p className='px-5 mx-2 pt-1'> <span className='homespan'> {t("home.span")} {t("home.span.ar")} </span> {t("home p")}</p>
                   <p className='px-5 mx-2 pt-1'>{t("home p2")}</p>
               </div>
            </SwiperSlide>
            <SwiperSlide><img src={pic1} alt="" />
               <div className='conne'>
                   <h1>{t("homehead")}</h1>
                   <p className='px-5 mx-2 pt-1'> <span className='homespan'> {t("home.span")} {t("home.span.ar")} </span> {t("home p")}</p>
                   <p className='px-5 mx-2 pt-1'>{t("home p2")}</p>
               </div>
            </SwiperSlide>
            <SwiperSlide><img src={pic3} alt="" />
               <div className='conne'>
                   <h1>{t("homehead")}</h1>
                   <p className='px-5 mx-2 pt-1'> <span className='homespan'> {t("home.span")} {t("home.span.ar")} </span> {t("home p")}</p>
                   <p className='px-5 mx-2 pt-1'>{t("home p2")}</p>
               </div>
            </SwiperSlide>

    </Swiper>
    </>

    }
