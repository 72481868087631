import React from 'react'
import  Navbar  from '../Navbar/Navbar'
import { Outlet } from 'react-router-dom'
import { useTranslation } from 'react-i18next';


export default function Layout() {
  const {i18n} = useTranslation()
  return <div dir={i18n.language == 'ar' ? 'rtl' : 'ltr'}>
   <Navbar/>
   <div>
   <Outlet/>
   </div>
  </div>
}
