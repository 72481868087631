function Items({data}){
    return <>
        <div className="row ">
        {
          data.map((value)=>{
           const {id, image, title, description , indd,} = value;
           return (
              <div className="col-md-4 my-2">
              <div className="card" >
                  {indd == "video" ? <video  controls  src={image[0]} type="video/mp4"> </video> : <img  src={image[0]} className="card-img-top" />}
              </div>
              </div>
           )
          })
        }

</div>
    </>
}

export default Items;
