import { useTranslation } from 'react-i18next';
/* Pic */
/*    Advertising  */
import v1 from "../Gallery/pic/3-ADV-Designs/v1.jpg"
import v2 from "../Gallery/pic/3-ADV-Designs/v2.jpg"
import v3 from "../Gallery/pic/3-ADV-Designs/v3.jpg"
import v4 from "../Gallery/pic/3-ADV-Designs/v4.jpg"
import v5 from "../Gallery/pic/3-ADV-Designs/v5.jpg"
import v6 from "../Gallery/pic/3-ADV-Designs/v6.jpg"
import v7 from "../Gallery/pic/3-ADV-Designs/v7.jpg"
import v8 from "../Gallery/pic/3-ADV-Designs/v8.jpg"
/*    printing  */
import p1 from "../Gallery/pic/2-Printing/p1.jpg"
import p2 from "../Gallery/pic/2-Printing/p2.jpg"
/*    3D  */
import d1 from "../Gallery/pic/4-3D/3d-1.jpg"
import d2 from "../Gallery/pic/4-3D/3d-2.jpg"
/*    Branding  */
import b1 from "../Gallery/pic/1-Branding/b1.jpg"
import b2 from "../Gallery/pic/1-Branding/b2.jpg"
/*    Motion  */
import motion1 from "../Gallery/pic/5-Motion VID/motion1.mp4"
import motion2 from "../Gallery/pic/5-Motion VID/motion2.mp4"
/*    Video  */


const data =[
    {
        id:1,
        image:[v1],
        title:"",
        description:"",
        category:"Advertising",
       
    },
    {
        id:2,
        image:[v2],
        title:"",
        description:"",
        category:"Advertising",
       
    },
    {
        id:3,
        image:[v3],
        title:"",
        description:"",
        category:"Advertising",
       
    },
    {
        id:4,
        image:[v4],
        title:"",
        description:"",
        category:"Advertising",
       
    },
    {
        id:5,
        image:[v5],
        title:"",
        description:"",
        category:"Advertising",
       
    },
    {
        id:6,
        image:[v6],
        title:"",
        description:"",
        category:"Advertising",
       
    },
    {
        id:7,
        image:[v7],
        title:"",
        description:"",
        category:"Advertising",
       
    },
    {
        id:8,
        image:[v8],
        title:"",
        description:"",
        category:"Advertising",
       
    },
    {
        id:9,
        image:[d1],
        title:"",
        description:"",
        category:"3d",
       
    },
    {
        id:10,
        image:[d2],
        title:"",
        description:"",
        category:"3d",
       
    },
    {
        id:12,
        image:[b1],
        title:"",
        description:"",
        category:"Branding",
       
    },
    {
        id:13,
        image:[b2],
        title:"",
        description:"",
        category:"Branding",
       
    },
    {
        id:14,
        indd : "video",
        image:[motion1],
        title:"",
        description:"",
        category:"Motion",
       
    },
    {
        id:15,
        indd : "video",
        image:[motion2],
        title:"",
        description:"",
        category:"Motion",
       
    },
]

export default data;