import brandingpic from "../../Pic/Services Components/branding.png";
import advertisitnpic from "../../Pic/Services Components/advertisitn.png";
import z3dpic from "../../Pic/Services Components/3d.png";
import motionpic from "../../Pic/Services Components/motion.png";
import videoeditingpic from "../../Pic/Services Components/video-editing.png";
import React from 'react';
import { useTranslation } from 'react-i18next';


export default function GraphicDesigns() {
    const { t, i18n } = useTranslation();
  return <>
    <div className='pt-5 mt-5  container'>
            <div className='row my-5 d-flex justify-content-center align-items-center'>
                <div className="col-md-7 col-10 ">
                    <div className='servicesItem'>
                            <h1 className={t("services_class")}>{t("graphicbranding")}</h1>
                            <p className="fw-bold">{t("graphicbranding_p")}</p>
                    </div>
                </div>
                <div className="col-md-5 col-10 ">
                    <div className='servicesItem'>
                            <img className='w-100 text-center' src={brandingpic}  alt="" />
                    </div>
                </div>
            </div>
            <div className='row my-5 d-flex justify-content-center align-items-center'>
                <div className="col-md-7 col-10 ">
                    <div className='servicesItem'>
                            <h1 className={t("services_class")}>{t("graphicadvertising")}</h1>
                            <p className="fw-bold" >{t("graphicadvertising_p")}</p>
                    </div>
                </div>
                <div className="col-md-5 col-10 ">
                    <div className='servicesItem'>
                            <img className='w-100 text-center' src={advertisitnpic}  alt="" />
                    </div>
                </div>
            </div>
            <div className='row my-5 d-flex justify-content-center align-items-center'>
                <div className="col-md-7 col-10 ">
                    <div className='servicesItem'>
                            <h1 className={t("services_class")}>{t("graphic3d")}</h1>
                            <p className="fw-bold" >{t("graphic3d_p")}</p>
                    </div>
                </div>
                <div className="col-md-5 col-10 ">
                    <div className='servicesItem'>
                            <img className='w-100 text-center' src={z3dpic}  alt="" />
                    </div>
                </div>
            </div>
            <div className='row my-5 d-flex justify-content-center align-items-center'>
                <div className="col-md-7 col-10 ">
                    <div className='servicesItem'>
                            <h1 className={t("services_class")}>{t("graphicmotion")}</h1>
                            <p className="fw-bold" >{t("graphicmotion_p")}</p>
                    </div>
                </div>
                <div className="col-md-5 col-10 ">
                    <div className='servicesItem'>
                            <img className='w-100 text-center' src={motionpic}  alt="" />
                    </div>
                </div>
            </div>
            <div className='row my-5 d-flex justify-content-center align-items-center'>
                <div className="col-md-7 col-10 ">
                    <div className='servicesItem'>
                            <h1 className={t("services_class")}>{t("graphicvideo")}</h1>
                            <p className="fw-bold" >{t("graphicvideo_p")}</p>
                    </div>
                </div>
                <div className="col-md-5 col-10 ">
                    <div className='servicesItem'>
                            <img className='w-100 text-center' src={videoeditingpic}  alt="" />
                    </div>
                </div>
            </div>
    </div>
  </>
}
