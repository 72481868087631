import React from 'react' ;
import { Link } from 'react-router-dom' ;
import logo from "../../Pic/logo-white2.png";
import { useTranslation } from 'react-i18next';


export default function Navbar() {
  const { t, i18n } = useTranslation();
  return <>

<nav className="navbar navbar-expand-lg bg-color fixed-top border-bottom" id='navbar'>
  <div className="container">
    <Link className="navbar-brand m-0 p-0" to=""><img className='logo' src={logo} alt="" /></Link>
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon"></span>
    </button>
    <div className="collapse navbar-collapse" id="navbarNav">
           <div className={t("navbar dropdown")}>
                <div>
                    <ul className="navbar-nav  me-5">
                        <li className="nav-item">
                          <Link className="nav-link" to="">{t('home')}</Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" to="services">{t('services')}</Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" to="about">{t("about")}</Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" to="contact">{t("contact")}</Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" to="gallery">{t("gallery")}</Link>
                        </li>
                    </ul>
                  </div>
                  <div>
                        <li class="nav-item dropdown list-unstyled">
                              <Link class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                              <i><img src={t("nav-flag")} alt="" /></i> {t("Language")}
                              </Link>
                              <ul class="dropdown-menu">

                                <li><Link class="dropdown-item" to="" onClick={()=>{
                                  i18n.changeLanguage('ar', (err, t) => {
                                    if (err) return console.log('something went wrong loading', err);
                                  });
                                }}><img src="https://img.icons8.com/office/30/null/saudi-arabia.png"/> <span className={t("navbar dropdown flag")}>عربى</span></Link>
                                </li>

                                <li><Link class="dropdown-item" to=""  onClick={()=>{
                                  i18n.changeLanguage('en', (err, t) => {})
                                }}><img src="https://img.icons8.com/office/30/null/great-britain.png"/> <span className={t("navbar dropdown flag")}>English</span></Link>
                                </li>
                              </ul>
                        </li>
                  </div>
           </div>
    </div>
  </div>
</nav>

  </>
}

