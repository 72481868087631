import React from 'react';
import { Link } from 'react-router-dom';
import Arrow_left from '../../../src/Pic/Services/Arrow-left.png';
import Arrow_right from '../../../src/Pic/Services/Arrow-right.png';
import pic1 from '../../../src/Pic/Services/pic1.png';
import pic2 from '../../../src/Pic/Services/pic2.png';
import pic3 from '../../../src/Pic/Services/pic3.png';

import { useTranslation } from 'react-i18next';

export default function Services() {
      const { t, i18n } = useTranslation();
  return <>
    <div className='pt-5 mt-5  container-fluid services-section'>
            <div className='my-5'>
                <div className='row d-flex justify-content-center align-items-center'>
                <div className="col-md-2 col-10 ">
                      <div className='servicesItem'>
                            <img className='w-100 text-center' src={i18n.language == 'en' ? Arrow_left : Arrow_right} alt="" />
                      </div>
                  </div>
                  <div className="offset-md-1  col-md-9 col-8 ">
                      <div className='servicesItem'>
                            <h1 className={t("our-services-head")}>{t("our Services")}</h1>
                            <p className='servisec-descripition'> {t("services-p-1")} </p>
                      </div>
                  </div>
                </div>
            </div>
            <div className='container'>
            <div className='row my-5 d-flex justify-content-center align-items-center'>
              <div className="col-md-7 col-10 ">
                  <div className='servicesItem'>
                        <h1 className={t("services_class")}>{t("Digital Marketing")}</h1>
                        <p>{t("Digital Marketing p")}</p>
                        <button className='btn btn-color'> <Link className='serviceslink' to="/digitalmarketing">{t("services button")}</Link>  </button>
                  </div>
              </div>
              <div className="col-md-5 col-10 ">
                  <div className='servicesItem'>
                        <img className='w-100 text-center' src={pic1} alt="" />
                  </div>
              </div>
            </div>
            <div className='row my-5 d-flex justify-content-center align-items-center'>
            <div className="col-md-5 col-10 ">
                  <div className='servicesItem'>
                        <img className='w-100 text-center' src={pic2} alt="" />
                  </div>
              </div>
              <div className="col-md-7 col-10 ">
                  <div className='servicesItem'>
                        <h1 className={t("services_class")}>{t("Graphic Designer")}</h1>
                        <p>{t("Graphic Designer p")}</p>
                        <button className='btn btn-color'> <Link className='serviceslink' to="/graphicdesigns">{t("services button")}</Link>  </button>
                  </div>
              </div>
            </div>
            <div className='row my-5 d-flex justify-content-center align-items-center'>
              <div className="col-md-7 col-10 ">
                  <div className='servicesItem'>
                        <h1 className={t("services_class")}>{t("Web Development")}</h1>
                        <p>{t("Web Development p")}</p>
                        <button className='btn btn-color'> <Link className='serviceslink' to="/webdevlopment">{t("services button")}</Link>  </button>
                  </div>
              </div>
              <div className="col-md-5 col-10 ">
                  <div className='servicesItem'>
                        <img className='w-100 text-center' src={pic3} alt="" />
                  </div>
              </div>
            </div>
            </div>
    </div>

  </>
}
