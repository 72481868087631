import marketingplanspic from "../../Pic/Services Components/marketing-plans.png";
import marketingsocialspic from "../../Pic/Services Components/Social.png";
import marketingseopic from "../../Pic/Services Components/seo.png";
import marketingsempic from "../../Pic/Services Components/sem.png";
import marketingcontentpic from "../../Pic/Services Components/content-creation.png";
import React from 'react';
import { useTranslation } from 'react-i18next';
export default function DigitalMarketing() {
  const { t, i18n } = useTranslation();
  return <>

<div className='pt-5 mt-5  container'>
            <div className='row my-5 d-flex justify-content-center align-items-center'>
                <div className="col-md-7 col-10 ">
                    <div className='servicesItem'>
                            <h1 className={t("services_class")}>{t("markeitingplans")}</h1>
                            <p className="fw-bold">{t("markeitingplans_p")}</p>
                    </div>
                </div>
                <div className="col-md-5 col-10 ">
                    <div className='servicesItem'>
                            <img className='w-100 text-center' src={marketingplanspic}  alt="" />
                    </div>
                </div>
            </div>
            <div className='row my-5 d-flex justify-content-center align-items-center'>
                <div className="col-md-7 col-10 ">
                    <div className='servicesItem'>
                            <h1 className={t("services_class")}>{t("markeitingsocial")}</h1>
                            <p className="fw-bold">{t("markeitingsocial_p")}</p>
                    </div>
                </div>
                <div className="col-md-5 col-10 ">
                    <div className='servicesItem'>
                            <img className='w-100 text-center' src={marketingsocialspic}  alt="" />
                    </div>
                </div>
            </div>
            <div className='row my-5 d-flex justify-content-center align-items-center'>
                <div className="col-md-7 col-10 ">
                    <div className='servicesItem'>
                            <h1 className={t("services_class")}>{t("markeitingseo")}</h1>
                            <p className="fw-bold">{t("markeitingseo_p")}</p>
                    </div>
                </div>
                <div className="col-md-5 col-10 ">
                    <div className='servicesItem'>
                            <img className='w-100 text-center' src={marketingseopic}  alt="" />
                    </div>
                </div>
            </div>
            <div className='row my-5 d-flex justify-content-center align-items-center'>
                <div className="col-md-7 col-10 ">
                    <div className='servicesItem'>
                            <h1 className={t("services_class")}>{t("markeitingsem")}</h1>
                            <p className="fw-bold">{t("markeitingsem_p")}</p>
                    </div>
                </div>
                <div className="col-md-5 col-10 ">
                    <div className='servicesItem'>
                            <img className='w-100 text-center' src={marketingsempic}  alt="" />
                    </div>
                </div>
            </div>
            <div className='row my-5 d-flex justify-content-center align-items-center'>
                <div className="col-md-7 col-10 ">
                    <div className='servicesItem'>
                            <h1 className={t("services_class")}>{t("markeitingcontent")}</h1>
                            <p className="fw-bold">{t("markeitingcontent_p")}</p>
                    </div>
                </div>
                <div className="col-md-5 col-10 ">
                    <div className='servicesItem'>
                            <img className='w-100 text-center' src={marketingcontentpic}  alt="" />
                    </div>
                </div>
            </div>
    </div>
  </>
}
