import React from 'react';
import pic1 from "../../Pic/contact/pic1.png";
import pic2 from "../../Pic/contact/pic2.png";
import pic3 from "../../Pic/contact/pic3.png";
import pic4 from "../../Pic/contact/pic4.png";
import { useTranslation } from 'react-i18next';

export default function Contact() {
    const { t } = useTranslation();
  return <>
  
  <div className='pt-5 mt-5  container-fluid contact-section'>
      <div className='row d-flex justify-content-center align-items-center'>
          <div className="col-md-6 col-10">
               <div className="contact-item">
                    <h1> {t("contact h1")} </h1>
                    <br />
                    <h1> {t("contact h2")} </h1>
               </div>
          </div>
          <div className="col-md-6 col-10">
               <div className="contact-item">
                  <img className='w-100' src={pic1} alt="" />
               </div>
          </div>
      </div>
      <div className='row d-flex justify-content-center text-center'>
           <div className='col-xl-4   col-md-6 col-10 mb-5  p-5'>
               <div className="social-item">
                     <h3> <img  src={pic2} alt="" /> {t("Email")} </h3>
                     <ul className="navbar-nav">
                         <li className="nav-item"> <span> {t("Email p")} </span> </li>
                     </ul>
               </div>
           </div>
           <div className='col-xl-4   col-md-6 col-10 mb-5  p-5'>
               <div className="social-item">
                     <h3> <img  src={pic3} alt="" /> {t("Social Media")} </h3>
                    <div className='social-links'>
                        <ul className="navbar-nav social-media-icons">
                            <div className='Align'><li className="nav-item"> <i class="fa-brands fa-square-facebook"></i>  <a className='nav-link' href="https://www.facebook.com/a3mali.business.Solution" target="_blank">{t("facebook")}</a> </li></div>
                            <div className='Align' ><li className="nav-item"> <i class="fa-brands fa-square-instagram"></i> <a className='nav-link' href="https://www.instagram.com/a3mali.ksa/" target="_blank">{t("instagram")}</a> </li></div>
                            <div className='Align' ><li className="nav-item"> <i class="fa-brands fa-linkedin"></i><a className='nav-link' href="https://www.linkedin.com/in/a3mali-marketing-54169b261/" target="_blank"> {t("linkedin")}</a> </li></div>
                            <div className='Align' ><li className="nav-item"> <i class="fa-brands fa-youtube"></i> <a className='nav-link' href="" target="_blank">{t("youtube")}</a> </li></div>
                        </ul>
                    </div>
               </div>
           </div>
           <div className='col-xl-4   col-md-6 col-10 mb-5  p-5'>
               <div className="social-item">
                     <h3> <img  src={pic4} alt="" /> {t("Call")} </h3>
                     <ul className="navbar-nav">
                         <li className="nav-item"> <a className='nav-link' href="https://wa.me/+966560525015" target="_blank"><span> {t("phone")} </span></a> </li>
                     </ul>
               </div>
           </div>
      </div>
  </div>

  </>
}
